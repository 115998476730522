
.ModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 1.2em;
  }

  p {
    margin: 18px 0px 0px 0px;
  }
}

.App {
  $screen-break: 1024px;

  display: flex;
  flex-direction: column;
  font-family: 'Helvetica Neue';
  background-image: url(img/main-bg.jpg);
  background-image: -webkit-image-set(
    url(img/main-bg.jpg) 1x,
    url(img/main-bg@2x.jpg) 2x,
    url(img/main-bg@3x.jpg) 3x,
  );
  background-image: image-set(
    url(img/main-bg.jpg) 1x,
    url(img/main-bg@2x.jpg) 2x,
    url(img/main-bg@3x.jpg) 3x,
  );
  background-repeat: no-repeat;
  background-size: contain;

  .App-Content {
    display: flex;
    flex-direction: column;
    margin-top: 40%;


    .App-MainBackgroundImage {
      width: 1024px;
      height: 600px;
      object-fit: contain;
    }

    .App-HeaderText {
      width: 86%;
      display: flex;
      flex-direction: row;
      margin-left: 7%;
      margin-right: 7%;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #c02140;
      @media screen and (max-width: $screen-break) { // small
        font-size: 1.8em;
      }
      @media screen and (min-width: $screen-break) { // large
        font-size: 3em;
      }
    }

    .App-SubHeader {
      width: 86%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-left: 7%;
      margin-right: 7%;

      span {
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.2px;
        text-align: center;
        color: #252525;
        @media screen and (max-width: $screen-break) { // small
          font-size: 1.2em;
        }
        @media screen and (min-width: $screen-break) { // large
          font-size: 1.6em;
        }
      }

      .App-SubHeaderSeparator {
        font-size: 2em;
        color: #2c0653;
        color: var(--dark-indigo);
        margin-left: 15px;
        margin-right: 15px;
      }
    }


    .App-GetInTouch {
      margin-left: 7%;
      margin-right: 7%;
      margin-top: 50px;

      p {
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #252525;
        margin: 0;
        @media screen and (max-width: $screen-break) { // small
          font-size: 1.3em;
        }
        @media screen and (min-width: $screen-break) { // large
          font-size: 2em;
        }
      }

      .App-Rectangle {
        width: 91px;
        height: 6px;
        margin-top: 10px;
        background-color: #000000;
      }
    }

    .App-AboutMe {
      margin-left: 7%;
      margin-right: 7%;
      margin-top: 20px;

      p {
        font-size: 1.1em;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: #252525;
      }
    }

    .App-Me {
      margin-left: 7%;
      margin-right: 7%;
      margin-top: 50px;

      p {
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #252525;
        margin: 0;
        @media screen and (max-width: $screen-break) { // small
          font-size: 1.2em;
        }
        @media screen and (min-width: $screen-break) { // large
          font-size: 1.5em;
        }
      }
    }

    .App-Contact {
      margin-left: 7%;
      margin-right: 7%;
      margin-bottom: 10px;

      div {
        margin-bottom: 8px;
        margin-top: 8px;
      }

      .link {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #252525;
        font-size: 1.1em;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .App-Icons {
      display: flex;
      flex-direction: row;
      margin-left: 7%;
      margin-right: 7%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .App-SocialIcons {
        .App-Icon {
          margin-right: 20px;
          margin-bottom: 8px;
        }
      }

      .App-CryptoIcons {
        .Crypto-Icon {
          margin-right: 20px;
          width: 48px;
          height: 48px;
          cursor: pointer;
          margin-bottom: 8px;
        }
      }
    }

    .App-FindMe {
      margin-left: 7%;
      margin-right: 7%;
      p {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #252525;
      }
    }
  }
}
